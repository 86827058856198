<template>
  <div></div>
</template>
<script>
import { setLogout } from '@/api/Cherry';

export default {
  name: 'SignOut',
  components: {},
  data() {
    return {
      cameFromWeb: false,
    };
  },
  mounted() {
    if (this.$route.query.web !== undefined) {
      this.cameFromWeb = true;
    }

    this.signOut();
  },
  methods: {
    signOut: async function () {
      // we can remove later but I left counter here just in case we want to reuse it
      await setLogout(); // logout event to our server
      await this.$gAuth.signOut();

      this.$store.dispatch('clearMeetingList');
      this.$store.dispatch('userResetState');

      const route = { name: 'signin' };
      if (this.cameFromWeb) route.query = { web: null };

      this.$router.replace(route);
    },
  },
};
</script>
